<!--
 * @Author: huwanjun
 * @Date: 2021-04-07 11:01:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 12:27:19
 * @Description: 客户详情
-->

<template>
  <NeoPageLayout>
    <div class="companyInfo">
      <!-- 标题 -->
      <h2 class="title">{{ companyName }}</h2>
      <!-- 分页 -->
      <div class="radio">
        <a-radio-group v-model="infoType">
          <a-radio-button value="baseInfo">{{ $t('companyInfo.base_info') }}</a-radio-button>
          <a-radio-button value="productionConfig">{{ $t('companyInfo.productionline_config') }}</a-radio-button>
          <a-radio-button value="priceList">{{ $t('companyInfo.price_list') }}</a-radio-button>
          <a-radio-button value="manager">{{ $t('companyInfo.manager') }}</a-radio-button>
          <a-radio-button value="customSet" :disabled="true">{{ $t('companyInfo.custom_set') }}</a-radio-button>
        </a-radio-group>
      </div>
      <!-- 基本信息 -->
      <div class="baseInfo" v-show="infoType === 'baseInfo'">
        <div class="title-btn-container">
          <NeoTitle :text="$t('companyInfo.base_info')" />
          <!-- 更新按钮 -->
          <template v-if="permission">
            <a-button type="primary btn-update" size="small" @click="onUpdate" v-if="btnShow === 'update'">{{
              $t('update')
            }}</a-button>
            <a-button
              type="primary btn-update"
              size="small"
              @click="onSave"
              v-if="btnShow === 'save'"
              :loading="btnSaveLoading"
              >{{ $t('save') }}</a-button
            >
          </template>
        </div>
        <!-- 基本信息描述列表 -->
        <a-descriptions bordered size="small" :column="2">
          <a-descriptions-item :label="$t('newCompany.company_code')"> {{ agencyInfo.code }} </a-descriptions-item>
          <a-descriptions-item :label="$t('newCompany.spm')">
            <!-- 更新编辑框 -->
            <a-select
              v-if="updateEdit"
              v-model="text"
              style="min-width: 420px"
              show-search
              :filter-option="filterOption"
            >
              <a-select-option v-for="item in SpmList" :key="item.id" :value="item.id">{{
                item.realName
              }}</a-select-option>
            </a-select>
            <template v-else>
              {{ agencyInfo.supplierSpmName }}
            </template>
          </a-descriptions-item>
          <a-descriptions-item :label="$t('newCompany.company_name')">
            {{ agencyInfo.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('newCompany.supplier_name')">
            {{ agencyInfo.supplierName }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('newCompany.company_address')">
            {{ agencyInfo.address }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('newCompany.supplier_address')">
            {{ agencyInfo.supplierAddress }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('newCompany.currency')">
            {{ agencyInfo.currency }}
          </a-descriptions-item>
          <a-descriptions-item> </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 产线配置 -->
      <div class="baseInfo" v-show="infoType === 'productionConfig'">
        <div class="title-btn-container">
          <NeoTitle :text="$t('companyInfo.productionline_config')" />
          <!-- 新增产线按钮 -->
          <a-button type="primary" size="small" @click="addProdutionLineVisible = true" v-if="permission">{{
            $t('companyInfo.btn_new_productionline')
          }}</a-button>
        </div>
        <!-- 产线配置table -->
        <a-table
          class="head-bg no-br"
          size="middle"
          bordered
          rowKey="id"
          :data-source="dataInfo"
          :pagination="plinePagination"
          :loading="PlineTableLoading"
        >
          <!-- 产线名 -->
          <a-table-column key="name" :title="$t('companyInfo.Pline_name')">
            <template slot-scope="text">
              {{ text.name | defined }}
            </template>
          </a-table-column>
          <!-- 产线配置 -->
          <a-table-column key="status" :title="$t('companyInfo.Pline_statu')">
            <template slot-scope="text">
              {{ localeDict.productionLineStatus[text.status] | defined }}
            </template>
          </a-table-column>
          <!-- 交付经理 -->
          <a-table-column key="supplierPmName" :title="$t('companyInfo.Pline_manager')">
            <template slot-scope="text">
              {{ text.supplierPmName | defined }}
            </template>
          </a-table-column>
          <!-- 生产对接 -->
          <a-table-column key="produceVersion" :title="$t('companyInfo.Pline_dock')">
            <template slot-scope="text"> {{ localeDict.systemDict[text.produceVersion] | defined }} </template>
          </a-table-column>
          <!-- 报价方式 -->
          <a-table-column key="quoteType" :title="$t('companyInfo.Pline_mode')">
            <template slot-scope="text">
              {{ getQuoteType(text.quoteType) | defined }}
            </template>
          </a-table-column>
          <!-- 更新时间 -->
          <a-table-column key="updateDate" :title="$t('companyInfo.Pline_updatetime')">
            <template slot-scope="text">
              {{ text.updateDate | defined }}
            </template>
          </a-table-column>
          <!-- 操作 -->
          <a-table-column key="action" :title="$t('companyInfo.action')">
            <template slot-scope="text">
              <a class="mr-3" @click="checkDetails(text)">{{ $t('companyInfo.action_details') }}</a>
              <template v-if="permission">
                <a v-if="!text.status" @click="onActiveProductionLine(text.id)">{{
                  $t('companyInfo.action_active')
                }}</a>
              </template>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <!-- 价格列表 -->
      <div class="priceList" v-show="infoType === 'priceList'">
        <div class="btn-container">
          <section class="fl" style="display: flex; margin-bottom: 10px">
            <!-- 新增价格按钮 -->
            <a-button type="primary" class="mr-2" @click="addPriceVisible = true" v-if="permission"
              >{{ $t('companyInfo.btn_new_price') }}
            </a-button>
            <NeoUploadButton
              :title="$t('companyInfo.btn_bulk_import')"
              v-model="priceFileList"
              :showNote="false"
              :showFileList="false"
              :neoStyle="true"
              accept=".xlsm"
              maxSize="5"
              v-if="permission"
            />
            <a-button
              type="link"
              href="https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/order_customer_price_template.xlsm"
            >
              {{ $t('companyInfo.btn_download') }} <a-icon type="download" />
            </a-button>
          </section>
          <section class="price-filter fr">
            <!-- @change="onSelectType" -->
            <a-select class="serviceType" :placeholder="$t('newOrder.service_type')" v-model="selectdata.serviceType">
              <a-select-option v-for="(item, index) of serviceType" :key="index" :value="item.dictValue">{{
                item.dictLabel
              }}</a-select-option>
            </a-select>
            <a-cascader
              v-model="selectdata.field"
              :options="industryList"
              :fieldNames="fielName"
              :placeholder="$t('newPline.industry_field_holder')"
              :disabled="selected"
              style="margin-left: 10px"
            ></a-cascader>
            <a-select class="documentType" :placeholder="$t('newOrder.doc_type')" v-model="selectdata.documentType">
              <a-select-option v-for="(item, index) of docType" :key="index" :value="item.dictValue">{{
                item.dictLabel
              }}</a-select-option>
            </a-select>
            <a-select
              allowClear
              show-search
              :filter-option="langFilterOption"
              class="sourceCode bigw"
              :placeholder="$t('newOrder.source')"
              v-model="selectdata.sourceCode"
            >
              <a-select-option v-for="(item, index) in langList" :key="index" :value="item.code">{{
                item.name
              }}</a-select-option>
            </a-select>
            <a-select
              allowClear
              show-search
              :filter-option="langFilterOption"
              class="targetCode bigw"
              :placeholder="$t('newOrder.target')"
              v-model="selectdata.targetCode"
            >
              <a-select-option v-for="(item, index) in langList" :key="index" :value="item.code">{{
                item.name
              }}</a-select-option>
            </a-select>

            <a-button class="reset" @click="reset">{{ $t('reset') }}</a-button>
            <a-button class="search" @click="search" type="primary">{{ $t('search') }}</a-button>
          </section>
          <section style="clear: both"></section>
        </div>
        <!-- 价格列表 table -->
        <a-table
          class="head-bg no-br"
          size="middle"
          rowKey="id"
          bordered
          :pagination="pricePagination"
          :data-source="priceInfo"
          :loading="priceTableLoading"
        >
          <a-table-column key="id" :title="$t('priceList.id')" align="center" width="200px">
            <template slot-scope="text">
              {{ text.code | defined }}
            </template>
          </a-table-column>
          <a-table-column key="serviceType" :title="$t('priceList.service_type')">
            <template slot-scope="text">
              {{ getServiceType(text.serviceType) | defined }}
            </template>
          </a-table-column>
          <a-table-column key="field" :title="$t('priceList.field')">
            <template slot-scope="text">
              {{ getField(text.field) | defined }}
            </template>
          </a-table-column>
          <a-table-column key="documentType" :title="$t('priceList.doc_type')">
            <template slot-scope="text">
              {{ getDocType(text.documentType) | defined }}
            </template>
          </a-table-column>
          <a-table-column key="sourceCode" :title="$t('priceList.source_language')">
            <template slot-scope="text">
              {{ getLanguage(text.sourceCode) | defined }}
            </template>
          </a-table-column>
          <a-table-column key="targetCode" :title="$t('priceList.target_language')">
            <template slot-scope="text">
              {{ getLanguage(text.targetCode) | defined }}
            </template>
          </a-table-column>
          <a-table-column key="price" :title="$t('priceList.price')">
            <template slot-scope="text">
              {{ text.price | defined }}
            </template>
          </a-table-column>
          <a-table-column key="unit" :title="$t('priceList.unit')">
            <template slot-scope="text">
              {{ getUnit(text.unit) | defined }}
            </template>
          </a-table-column>
          <a-table-column key="currency" :title="$t('priceList.currency')">
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template slot-scope="text"> {{ agencyInfo.currency }}</template>
          </a-table-column>
          <a-table-column key="action" :title="$t('companyInfo.action')" v-if="permission">
            <template slot-scope="text, record">
              <!-- 编辑价格 -->
              <a class="mr-3" @click="onEditPrice(text)">{{ $t('companyInfo.action_edit') }}</a>
              <!-- 删除价格 -->
              <a-popconfirm
                :title="$t('prompt.info', { handle: $t('delete') })"
                :ok-text="$t('confirm')"
                :cancel-text="$t('cancel')"
                @confirm="() => onDeletePrice(record.id)"
                @cancel="() => {}"
              >
                <a href="#">{{ $t('companyInfo.action_delete') }}</a>
              </a-popconfirm>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <!-- 下单经理 -->
      <div class="manager" v-show="infoType === 'manager'">
        <div class="btn-container">
          <a-button type="primary" class="mr-2" @click="addManagerVisible = true" v-if="permission">{{
            $t('companyInfo.btn_new_manager')
          }}</a-button>
          <NeoUploadButton
            :title="$t('companyInfo.btn_bulk_import')"
            v-model="managerFileList"
            :showNote="false"
            :showFileList="false"
            :neoStyle="true"
            accept=".xlsx"
            maxSize="5"
            v-if="permission"
          />
          <a-button
            type="link"
            href="https://genplus-common.oss-cn-shanghai.aliyuncs.com/excel_template/order_customer_info_template.xlsx"
          >
            {{ $t('companyInfo.btn_download') }} <a-icon type="download" />
          </a-button>
        </div>
        <!-- 下单经理列表 table -->
        <a-table
          class="head-bg no-br"
          size="middle"
          rowKey="id"
          bordered
          :pagination="managerPagination"
          :data-source="managerInfo"
          :loading="managerLoading"
        >
          <!-- 姓名 -->
          <a-table-column :title="$t('managerInfo.name')" key="name" align="center" :width="150">
            <template slot-scope="text">
              {{ text.name | defined }}
            </template>
          </a-table-column>
          <!-- 邮箱 -->
          <a-table-column :title="$t('managerInfo.email')" key="email" :width="200">
            <template slot-scope="text">
              {{ text.email | defined }}
            </template>
          </a-table-column>
          <!-- 手机 -->
          <a-table-column :title="$t('managerInfo.phone_number')" key="phone" :width="200">
            <template slot-scope="text">
              {{ text.phone === '' ? '---' : text.phone | defined }}
            </template>
          </a-table-column>
          <!-- 部门 -->
          <a-table-column :title="$t('managerInfo.department')" key="department">
            <template slot-scope="text">
              {{ text.department === '' ? '---' : text.department | defined }}
            </template>
          </a-table-column>
          <!-- 状态 -->
          <a-table-column :title="$t('managerInfo.statu')" key="status" align="center">
            <template slot-scope="text">
              {{ text.status ? $t('managerInfo.job') : $t('managerInfo.departure') | defined }}
            </template>
          </a-table-column>
          <!-- 权限 -->
          <a-table-column :title="$t('managerInfo.senior_manager')" key="seniorManager">
            <template slot-scope="text">
              {{ text.seniorManager ? $t('managerInfo.admin') : $t('managerInfo.manager') | defined }}
            </template>
          </a-table-column>
          <!-- 加入时间 -->
          <a-table-column :title="$t('managerInfo.join_time')" key="createDate">
            <template slot-scope="text">
              {{ text.createDate | dateFormat }}
            </template>
          </a-table-column>
          <!-- 操作 -->
          <a-table-column :title="$t('companyInfo.action')" key="action" v-if="permission">
            <template slot-scope="record">
              <!-- 编辑下单经理 -->
              <a class="mr-3" @click="onEditManager(record)">{{ $t('companyInfo.action_edit') }}</a>
              <!-- 注销下单经理 -->
              <a @click="onLogout(record)">{{
                record.status ? $t('managerInfo.set_departure') : $t('managerInfo.set_jon')
              }}</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
    <!-- 新增产线侧抽屉 -->
    <a-drawer
      width="960px"
      placement="right"
      :title="$t('newPline.add_Pline')"
      :visible="addProdutionLineVisible"
      @close="addProdutionLineVisible = false"
      :destroyOnClose="true"
    >
      <AddProductionLine :id="agencyInfo.id" v-on:show="onAddProductionLine" :clientName="agencyInfo.name" />
    </a-drawer>
    <!-- 查看详情侧抽屉 -->
    <a-drawer
      width="960px"
      placement="right"
      :title="productionLineName"
      :visible="checkDetailsVisible"
      @close="checkDetailsVisible = false"
      :destroyOnClose="true"
    >
      <ProductionLineDetails
        :id="PlineId"
        :agencyId="agencyInfo.id"
        v-on:update="updateProductionLine"
        :updatePermission="permission"
        :neoVersion="produceVersion"
        :clientName="agencyInfo.name"
      />
    </a-drawer>
    <!-- 新增价格面板 -->
    <a-modal
      :title="priceEdit ? $t('companyInfo.title_edit_price') : $t('companyInfo.title_new_price')"
      :visible="addPriceVisible"
      :footer="null"
      @cancel=";(addPriceVisible = false), (priceEdit = false)"
      :width="684"
      :maskClosable="false"
    >
      <AddPrice
        :id="agencyInfo.id"
        :currency="agencyInfo.currency"
        @addPrice="onAddPrice"
        v-if="addPriceVisible"
        :priceInfo="selectedPrice"
        :edit="priceEdit"
      />
    </a-modal>
    <!-- 新增下单经理侧抽屉 -->
    <a-drawer
      width="460px"
      :title="managerEdit ? $t('companyInfo.edit_manager_title') : $t('companyInfo.new_manager_title')"
      placement="right"
      :visible="addManagerVisible"
      @close=";(addManagerVisible = false), (managerEdit = false)"
    >
      <AddManager
        v-on:show="editManagerInfo"
        :id="agencyInfo.id"
        v-if="addManagerVisible"
        :managerInfo="selectedManager"
        :edit="managerEdit"
      />
    </a-drawer>
  </NeoPageLayout>
</template>

<script>
import { mapState } from 'vuex'
import AddPrice from './components/AddPrice'
import AddManager from './components/AddManager'
import AddProductionLine from './components/AddProductionLine'
import ProductionLineDetails from './components/ProductionLineDetails'
import NeoUploadButton from '@/components/upload/NeoUploadButton'
const { localeDict } = window.$g

// import moment from 'moment'
export default {
  name: 'companyInfo',
  components: { AddPrice, AddManager, AddProductionLine, ProductionLineDetails, NeoUploadButton },
  data() {
    return {
      permission: undefined,
      localeDict,
      companyName: '',
      infoType: 'baseInfo',
      // infoType: 'baseInfo',
      agencyInfo: {},
      // text
      text: undefined,
      // input框显示判断
      updateEdit: false,
      btnShow: 'update',
      // 产线名
      productionLineName: '',
      // 产线配置table-info
      dataInfo: [],
      PlineTableLoading: false,
      plinePagination: {
        current: 1,
        size: 'middle',
        pageSize: 20,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        total: 0,
        onShowSizeChange: (current, pageSize) => {
          this.plinePagination.current = 1
          this.plinePagination.pageSize = pageSize
          this.getProdutionLineInfo()
        },
        onChange: (page) => {
          this.plinePagination.current = page
          this.getProdutionLineInfo()
        },
      },
      PlineId: 0,
      priceInfo: [],
      priceTableLoading: false,
      pricePagination: {
        current: 1,
        size: 'middle',
        total: 0,
        pageSize: 20,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => {
          this.pricePagination.current = 1
          this.pricePagination.pageSize = pageSize
          this.getPriceList()
        },
        onChange: (page) => {
          this.pricePagination.current = page
          this.getPriceList()
        },
      },
      priceFileList: [],
      priceEdit: false,
      // 下单经理table-info
      managerInfo: [],
      managerLoading: false,
      managerPagination: {
        current: 1,
        total: 0,
        size: 'middle',
        pageSize: 20,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => {
          this.managerPagination.current = 1
          this.managerPagination.pageSize = pageSize
          this.getManagerInfo()
        },
        onChange: (page) => {
          this.managerPagination.current = page
          this.getManagerInfo()
        },
      },
      managerFileList: [],
      managerEdit: false,
      selectedPrice: {},
      selectedManager: {},
      // 价格列表table-info
      addPriceVisible: false,
      addManagerVisible: false,
      addProdutionLineVisible: false,
      checkDetailsVisible: false,
      produceVersion: undefined,
      SpmList: [],
      btnSaveLoading: false,
      selectdata: {
        serviceType: undefined,
        field: undefined,
        documentType: undefined,
        sourceCode: undefined,
        targetCode: undefined,
      },
      // options
      fielName: {
        value: 'dictValue',
        label: 'dictLabel',
        children: 'children',
      },
      selected: false,
    }
  },
  mounted() {
    this.companyName = this.$route.query.extraName
    this.getProdutionLineInfo()
    this.getPriceList()
    this.getAgencyInfo()
    this.getManagerInfo()
    this.getSpmList()
  },
  watch: {
    priceFileList(newFile) {
      const file = newFile[newFile.length - 1] || {}
      if (file.status === 'done') {
        const result = this.importPrice(file.url)
        result.then((value) => {
          if (value) {
            this.getPriceList()
          }
        })
      }
    },
    managerFileList(newFile) {
      const file = newFile[newFile.length - 1] || {}
      if (file.status === 'done') {
        const data = this.importManager(file.url)
        data.then((value) => {
          if (value) {
            this.getManagerInfo()
          }
        })
      }
    },
    $route() {
      this.companyName = this.$route.query.extraName
      this.getProdutionLineInfo()
      this.getPriceList()
      this.getAgencyInfo()
      this.getManagerInfo()
      this.getSpmList()
    },
    infoType(val) {
      if (val !== 'priceList') {
        this.reset()
      }
    },
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
      langList: (state) => state.langList,
      industryList: (state) => state.industryList,
    }),
    serviceType() {
      return this.$store.getters['app/getDictByType']('SERVICE_TYPE')
    },
    docType() {
      return this.$store.getters['app/getDictByType']('DOC_TYPE')
    },
  },
  methods: {
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    onSelectType(val) {
      if (val !== '1-1' && val !== '1-2') {
        this.selected = true
      } else {
        this.selected = false
      }
      this.selectdata.field = undefined
      this.selectdata.sourceCode = undefined
      this.selectdata.targetCode = undefined
    },
    // 权限函数
    getPermission() {
      if (this.$role('companyInfo_update')) {
        // 管理员或当前登录人与高级交付经理一致
        if (
          this.userInfo.superTenant === 1 ||
          (this.userInfo.superTenant === 0 && this.userInfo.id === this.agencyInfo.supplierSpm)
        ) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    // 更新按钮
    onUpdate() {
      this.updateEdit = true
      this.btnShow = 'save'
    },
    // 保存按钮
    async onSave() {
      this.btnSaveLoading = true
      try {
        const data = await this.$http({
          key: 'changeSpm',
          query: {
            id: this.agencyInfo.id,
            userId: this.text,
          },
        })
        data && this.getAgencyInfo()
        this.btnSaveLoading = false
        this.$message.success(this.$t('prompt.update_success'))
      } catch (err) {
        this.btnSaveLoading = false
        this.$httpNotify(err)
      }
      this.updateEdit = false
      this.btnShow = 'update'
    },
    // 下单经理-注销操作响应事件
    async onLogout(value) {
      const s = value.status ? 0 : 1
      try {
        const data = await this.$http({
          key: 'setStatus',
          query: {
            id: value.id,
            status: s,
          },
        })
        if (data) {
          this.getManagerInfo()
          this.$message.success(this.$t('prompt.set_success'))
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 查看详情
    checkDetails(val) {
      this.productionLineName = val.name
      this.PlineId = val.id
      this.checkDetailsVisible = true
      this.produceVersion = val.produceVersion
    },
    // 删除价格按钮
    onDeletePrice(val) {
      this.deletePrice(val)
    },
    // 删除价格
    async deletePrice(value) {
      try {
        const data = await this.$http('deletePrice', [value])
        if (data) {
          this.getPriceList()
          this.$message.success(this.$t('prompt.delete_success'))
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 获取产线信息集合
    async getProdutionLineInfo() {
      try {
        this.PlineTableLoading = true
        const data = await this.$http('productionLineInfo', {
          agencyId: this.$route.query.id,
          limit: this.plinePagination.pageSize,
          page: this.plinePagination.current,
        })
        this.PlineTableLoading = false
        this.dataInfo = data.list
        this.plinePagination.total = data.total
      } catch (err) {
        this.PlineTableLoading = false
        this.$httpNotify(err)
      }
    },
    setSearchForm() {
      this.searchForm = {
        serviceType: this.selectdata.serviceType,
        field: this.selectdata.field ? this.selectdata.field[1] : undefined,
        documentType: this.selectdata.documentType,
        sourceCode: this.selectdata.sourceCode,
        targetCode: this.selectdata.targetCode,
      }
    },
    search() {
      this.pricePagination.current = 1
      this.setSearchForm()
      this.getPriceList()
    },
    reset() {
      this.pricePagination.current = 1
      for (const key in this.selectdata) {
        this.selectdata[key] = undefined
      }
      this.setSearchForm()
      this.getPriceList()
    },
    // 根据客户实体获取服务类型价格
    async getPriceList() {
      try {
        this.priceTableLoading = true
        const data = await this.$http('priceList', {
          limit: this.pricePagination.pageSize,
          page: this.pricePagination.current,
          agencyId: this.$route.query.id,
          serviceType: this.selectdata.serviceType,
          field: this.selectdata.field ? this.selectdata.field[1] : undefined,
          documentType: this.selectdata.documentType,
          sourceCode: this.selectdata.sourceCode,
          targetCode: this.selectdata.targetCode,
        })
        this.priceTableLoading = false
        this.priceInfo = data.list
        this.pricePagination.total = data.total
      } catch (err) {
        this.priceTableLoading = false
        this.$httpNotify(err)
      }
    },
    // 获取基本企业基本信息
    async getAgencyInfo() {
      try {
        const data = await this.$http({
          key: 'agencyInfo',
          params: { id: this.$route.query.id },
        })
        this.agencyInfo = data
        this.agencyInfo.currency = this.getCurrency(this.agencyInfo.currency)
        this.permission = this.getPermission()
        this.text = this.agencyInfo.supplierSpm
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 根据客户实体获得下单经理信息
    async getManagerInfo() {
      try {
        this.managerLoading = true
        const data = await this.$http('managerInfo', {
          limit: this.managerPagination.pageSize,
          page: this.managerPagination.current,
          agencyId: this.$route.query.id,
        })
        this.managerLoading = false
        this.managerInfo = data.list
        this.managerPagination.total = data.total
      } catch (err) {
        this.managerLoading = false
        this.$httpNotify(err)
      }
    },
    // 下单经理子组件触发父组件
    editManagerInfo() {
      this.getManagerInfo()
      this.addManagerVisible = false
      this.managerEdit = false
    },
    // 导入价格
    async importPrice(val) {
      try {
        const data = await this.$http('importPrice', {
          agencyId: this.agencyInfo.id,
          fileUrl: val,
        })
        return data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 编辑价格
    onEditPrice(val) {
      this.selectedPrice = val
      this.priceEdit = true
      this.addPriceVisible = true
    },
    // 价格子组件触发的父组件的
    onAddPrice() {
      this.getPriceList()
      this.addPriceVisible = false
      this.priceEdit = false
    },
    // 导入下单经理
    async importManager(val) {
      try {
        const data = await this.$http('importManager', {
          agencyId: this.agencyInfo.id,
          fileUrl: val,
        })
        return data
      } catch (err) {
        // this.$message.error(err.msg)
        // TODO：修改
        this.$httpNotify(err)
      }
    },
    // 编辑下单经理
    onEditManager(record) {
      this.selectedManager = record
      this.addManagerVisible = true
      this.managerEdit = true
    },
    onAddProductionLine() {
      this.getProdutionLineInfo()
      this.addProdutionLineVisible = false
    },
    // 激活产线
    async onActiveProductionLine(val) {
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('activeProductionLine', {
          id: val,
        })
        this.getProdutionLineInfo()
        this.$message.success(this.$t('companyInfo.active_success'))
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 产线详情被修改
    updateProductionLine() {
      this.checkDetailsVisible = false
      this.getProdutionLineInfo()
      this.$message.success(this.$t('prompt.update_success'))
    },
    getVersion(val) {
      return this.$store.getters['app/getDictLabel']('system', val)
    },
    getQuoteType(val) {
      return val == 1 ? this.$t('companyInfo.artificial_offer') : this.$t('companyInfo.automatic_offer')
    },
    getServiceType(val) {
      return this.$store.getters['app/getDictLabel']('SERVICE_TYPE', val)
    },
    getDocType(val) {
      return this.$store.getters['app/getDictLabel']('DOC_TYPE', val)
    },
    getLanguage(val) {
      const data = this.$store.getters['app/getLangNameByCode'](val)
      return val === '' ? '---' : data
    },
    getUnit(val) {
      return this.$store.getters['app/getDictLabel']('SALE_UNIT', val)
    },
    getField(val) {
      const data = this.$store.getters['app/getIndustryName'](val)
      let result = ''
      if (val === '' || val === null) {
        result = '---'
      } else {
        result = data.length === 2 ? `${data[0]} / ${data[1]}` : `${data[1]} / ${data[2]}`
      }
      return result
    },
    getCurrency(val) {
      return this.$store.getters['app/getDictLabel']('CURRENCY', val).split(';')[0]
    },
    // 获取高级交付经理列表
    async getSpmList() {
      try {
        const data = await this.$http('getSpmList')
        this.SpmList = data
        if (this.userInfo.superTenant === 1) {
          this.SpmList.push({
            id: this.userInfo.id,
            realName: this.userInfo.realName,
          })
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 下拉过滤
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 获取对应的字典列表
    getDictList(type) {
      return this.$store.getters['app/getDictByType'](type)
    },
  },
}
</script>

<style lang="less" scoped>
.companyInfo {
  padding: 30px 20px 0;
  .title {
    font-weight: bold;
    font-size: @font_size_6;
  }
  .radio {
    font-weight: bold;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .priceList .btn-container {
    display: block;
    // justify-content: space-between;
    .price-filter {
      .ant-select {
        width: 120px;
        margin-left: 10px;
      }
      .ant-btn {
        margin-left: 10px;
      }
      .bigw {
        width: 210px;
      }
    }
  }
  .btn-container {
    display: flex;
    margin: 20px 0;
  }
  .btn-update {
    min-width: 100px;
  }
  a {
    color: @primary-color;
  }
  .baseInfo {
    .title-btn-container {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 15px;
    }
    /deep/.ant-descriptions-item-colon {
      width: 135px;
      font-weight: bold;
      color: @text_color_2;
    }
  }
}
</style>
